

































































import { PropType } from 'vue'
import mixins from 'vue-typed-mixins'
import debounce from 'just-debounce-it'
import { getElasticSearchClient } from '@/services/elasticSearch'
import ProductsTableMixin from '@/mixins/ProductsTable.mixin'

enum ProductType {
  Physical = 'physical',
  Virtual = 'virtual',
}

interface Row {
  productId: string
  title: string
  variants: {
    productId: string
    variantId: string
    title: string
    trackQuantity: boolean
    variantType: string
    quantity: number
  }[]
  createdAt: Date
  updatedAt: Date
}

export default mixins(ProductsTableMixin).extend({
  name: 'AddProductsModal',
  props: {
    existingProductIds: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
  data() {
    return {
      checkedRows: [] as Row[],
      currentPage: 1,
      itemsPerPage: 25,
      search: '',
      searchInput: '',
      searchResult: [] as Record<string, any>,
    }
  },
  computed: {
    query(): Record<string, any> {
      const query: Record<string, any> = {
        query: {
          bool: {
            filter: [],
            must: [],
          },
        },
        track_total_hits: true,
        sort: this.sort,
        size: this.itemsPerPage,
        from: (this.currentPage - 1) * this.itemsPerPage,
      }

      if (this.search && this.search.length > 0) {
        query.query.bool.filter.push({
          match_phrase: { title: this.search },
        })
      }

      if (
        query.query.bool.filter.length === 0 &&
        query.query.bool.must.length === 0
      ) {
        query.query = {
          match_all: {},
        }
      }

      return query
    },
    nbHits(): number {
      return this.searchResult && this.searchResult.hits
        ? this.searchResult.hits.total.value
        : 0
    },
    rows(): Row[] {
      if (!this.searchResult || this.searchResult.length === 0) {
        return []
      }
      return this.searchResult.hits.hits.map((h: { _source: Row }) => h._source)
    },
    checkedProductIds(): string[] {
      return this.checkedRows.map((row) => row.productId)
    },
  },
  watch: {
    searchInput: {
      immediate: true,
      handler: debounce(function () {
        //@ts-ignore
        this.search = this.searchInput.trim()
      }, 500),
    },
    query: {
      immediate: true,
      deep: true,
      handler() {
        this.fetchData()
      },
    },
    rows(rows: Row[]) {
      this.checkedRows = rows.filter((row) =>
        this.existingProductIds.includes(row.productId)
      )
    },
  },
  methods: {
    debouncedSearchInput: debounce(function (this: any, value: string) {
      this.search = value
    }, 500),
    async fetchData(): Promise<void> {
      const elasticSearch = getElasticSearchClient()
      const { data } = await elasticSearch.post(
        '/inventory_products/_search',
        this.query
      )
      this.searchResult = data
    },
    getVariantText(data: Record<string, any>) {
      const { variants } = data
      if (variants && variants.length > 0) {
        return `for ${variants.length} ${
          variants.length > 1 ? 'variants' : 'variant'
        }`
      } else {
        return ''
      }
    },
    // TODO: types
    setCheckedRows(checkedRows: Row[]) {
      this.checkedRows = checkedRows
    },
    // TODO: types
    isRowChecked(a: any, b: any) {
      return a.productId === b.productId
    },
    // TODO: types
    getTotalVariantsQuantity(variants: any[]) {
      return variants.reduce((total, variant) => {
        if (variant.trackQuantity) {
          return (total += variant.quantity)
        }

        return total
      }, 0)
    },
    isQuantityZero(product: any) {
      return this.getTotalVariantsQuantity(product.variants) === 0
    },
    // TODO: types
    getProductType(variants: any[]) {
      if (variants.every(this.isTypePhysical)) {
        return 'Physical'
      }

      if (variants.every(this.isTypeVirtual)) {
        return 'Virtual'
      }

      return 'Mixed'
    },
    isTypePhysical(variant: Record<string, any>) {
      return variant.type === ProductType.Physical
    },
    isTypeVirtual(variant: Record<string, any>) {
      return variant.type === ProductType.Virtual
    },
    isQuantityTracked(product: any) {
      return product.variants.some((variant: any) => variant.trackQuantity)
    },
    /**
     * Set loading state
     *
     * @param isLoading - current loading state
     */
    setLoading(isLoading: boolean) {
      this.isLoading = isLoading
    },
    /**
     * Close the modal
     */
    close() {
      this.$emit('close')
    },
    /**
     * Method to handle save button
     *
     * Emits an event with selected product variants & close method
     */
    async handleSave() {
      this.setLoading(true)
      this.$emit('save', this.checkedProductIds, this.close)
    },
  },
})
