

























































import Vue from 'vue'
import NavigateBack from '@/components/shared/NavigateBack.vue'
import AddProductsModal from '@/components/products/AddProductsModal.vue'
import AddFulfillmentGroupImage from '@/components/fulfillment/AddFulfillmentGroupImage.vue'
import { getProduct } from '@/lib/product'
import { Product } from '../../../../shared/types/types'
import axios from 'axios'
import { getCurrentUser } from '@/lib/user'

export default Vue.extend({
  name: 'FulfillmentGroupForm',
  components: {
    AddFulfillmentGroupImage,
    NavigateBack,
  },
  data() {
    return {
      title: '',
      imageUrl: '',
      products: [] as Product[],
    }
  },
  methods: {
    openAddProductsModal() {
      this.$buefy.modal.open({
        parent: this,
        component: AddProductsModal,
        hasModalCard: true,
        trapFocus: true,
        props: {
          existingProductIds: this.products.map((product) => product.productId),
        },
        events: {
          save: async (
            selectedProductsIds: string[],
            closeModal: () => void
          ) => {
            const products = (await Promise.all(
              selectedProductsIds.map((productId) => getProduct(productId))
            )) as Product[]

            this.products = products

            closeModal()
          },
        },
      })
    },
    updateImage(imageUrl: string) {
      this.imageUrl = imageUrl
    },
    removeProduct(product: Product) {
      this.products = this.products.filter(
        (p) => p.productId !== product.productId
      )
    },
    async handleSave() {
      const currentUser = await getCurrentUser()

      const { data } = await axios.post(
        `${process.env.VUE_APP_FULFILLMENT_ENDPOINT}/groups`,
        {
          title: this.title,
          productIds: this.products.map((product) => product.productId),
          ...(this.imageUrl !== '' ? { imageUrl: this.imageUrl } : {}),
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: currentUser.signInUserSession.idToken.jwtToken,
          },
        }
      )

      this.$router.push(`/fulfillment/groups/${data.fulfillmentGroupId}`)
    },
  },
})
